<template>
    <div>                     
        <div class="row" v-if="question.type === 'address'">
            <div class="col-12 mb-4">
                <label :for="question.key + '_number'">House name or number</label>
                <input
                    :id="question.key + '_number'"
                    class="form-control"
                    :class="{ currency: question.currency }"
                >
            </div>

            <div class="col-12 mb-4">
                <label :for="question.key + '_postcode'">Postcode</label>
                <input
                    :id="question.key + '_postcode'"
                    class="form-control"
                    :class="{ currency: question.currency }"
                    placeholder="UK postcode"
                >
            </div>

            <div class="col-12">
                <button class="btn btn-primary w-100">Find address</button>

                <p>
                    <button>Enter address manually</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['question'],

    data() {
        return {
            value: null,
        }
    },

    methods: {
        update: function (event) {
            this.value = event.target.value;

            this.$emit('updated', {
                key: this.question.key,
                value: this.value
            });
        },
    }    
}
</script>